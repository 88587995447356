/* stylelint-disable */

.mentions {
  line-height: 22px;

  .mentions__highlighter {
    padding: 0.95rem 1.1rem 0.8rem;

    strong {
      color: $secondary;
    }
  }

  .mentions__suggestions {
    border-radius: 6px;
    box-shadow: $box-shadow;
    padding: 0.6rem 0;
    overflow-y: auto;
    max-height: 40vh;
  }

  .mentions__suggestions__item {
    padding: 0.3rem 1rem;

    &:hover {
      color: $secondary;
      background: $gray-400;
    }
  }
}
